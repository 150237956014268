$(document).ready(function() {
	
    $("#lightgallery").lightGallery({
        selector: '.GalleryBlock__item',
        download: false,
        googlePlus: false,
        pinterest: false,
        actualSize: false,
        getCaptionFromTitleOrAlt: false,
        rotate: false,
        share: false,
        flip: false,
        autoplay: false,
    });

	$('#launchGallery, #launchGalleryLarge').click(function(){
        $('#firstImage').trigger('click');
    })
    
	$("#lightfloorplan").lightGallery({
        selector: '.FloorplanBlock__item',
        download: false,
        googlePlus: false,
        pinterest: false,
        actualSize: false,
        getCaptionFromTitleOrAlt: true,
        rotate: false,
        share: false,
        flip: false,
        autoplay: false,
    });

	$('#launchGallery, #launchGalleryLarge').click(function(){
        $('#firstImage').trigger('click');
    })

    $('button.glide-arrow').on('click', function( event ){
        event.preventDefault();
        event.stopPropagation();
    });

});

